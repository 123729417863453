import React, { useState, useEffect } from "react";
import { API } from "../../_helpers/service";
import { Link } from "react-router-dom";
import { Table, notification, Space, Popconfirm, Button, DatePicker, Form, Select, Spin } from "antd";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { PageTitle } from "../../components";
import moment from "moment";

const { Option } = Select;

function KitchenHistory({ userData, schoolClass }) {
  const [form] = Form.useForm();
  const [isTeacher, setIsTeacher] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supplyId, setSupplyId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showNum] = useState(10);
  
  useEffect(() => {
    getClasses();
    setIsAdmin(userData.userRoles.includes("ROLE_ADMIN"));
    setIsTeacher(
      !userData.userRoles.includes("ROLE_EMPLOYEE") && userData.userRoles.includes("ROLE_TEACHER")
    );
  }, [userData.userRoles]);

  const getClasses = () => {
    API.post("class/all", { key: "bookName", value: null, operation: "LIKE" }, { params: { page: 0, size: 50 } })
      .then((result) => {
        if (result.status === 200) {
          setCategories(result.data.content);
        }
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    API.get(`kitchen/class-supply/${values.class}`, {
      params: { startDate: moment(values.date).format("YYYY/MM/DD") },
    }).then((result) => {
      if (result.status === 200 && result.data.success) {
        setData(result.data.content.students);
        setSupplyId(result.data.content.supplyId);
      }
      setLoading(false);
    });
  };

  const confirm = (studentId) => {
    API.post("kitchen/remove-food-count", { studentId, supplyId }).then((result) => {
      notification[result.data.success ? "success" : "error"]({
        message: result.data.success ? "Амжилттай" : "Амжилтгүй",
        description: result.data.message,
      });
      if (result.data.success) form.submit();
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (_, __, index) => (currentPage - 1) * showNum + index + 1,
    },
    {
      title: "Сурагчийн нэр",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => <Link to={`/student/${record.studentId}`}>{text}</Link>,
    },
    isAdmin && {
      title: "Үйлдэл",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm title="Буцаах уу?" onConfirm={() => confirm(record.studentId)} okText="Тийм" cancelText="Үгүй">
            <a href="#" style={{ color: "red" }}>Буцаах</a>
          </Popconfirm>
        </Space>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <PageTitle title="Хоол гарсан түүх" description="Хоол гарсан түүх" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form layout="inline" onFinish={onFinish} form={form} style={{ marginBottom: 10 }}>
                    <Form.Item name="class" label="Анги" rules={[{ required: true, message: "Анги сонгоно уу!" }]}> 
                      <Select style={{ width: 300 }} allowClear>
                        {(isTeacher ? schoolClass : categories).map((item) => (
                          <Option key={item.classId} value={item.classId}>{item.displayName}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="date" label="Өдөр" rules={[{ required: true, message: "Өдөр сонгоно уу!" }]}> 
                      <DatePicker />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">Харах</Button>
                    </Form.Item>
                  </Form>
                  <Spin spinning={loading}>
                    <Table columns={columns} dataSource={data} pagination={false} rowKey="id" className="table table-centered table-hover mb-0" />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapState = (state) => {
  const { authentication } = state;
  return { userData: authentication.user, schoolClass: authentication.schoolClass };
};

const connectedKitchenHistory = connect(mapState, { getUsers: userActions.getAll, deleteUser: userActions.delete })(KitchenHistory);
export { connectedKitchenHistory as KitchenHistory };