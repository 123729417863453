import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { API } from "../../_helpers";
import {
  Form,
  Input,
  Button,
  message,
  Alert,
  Divider,
  Spin,
  Tag,
  Modal,
} from "antd";
import { DotLoader } from "react-spinners";

import "react-responsive-carousel/lib/styles/carousel.min.css";

function PreSchool({ logout }) {
  useEffect(() => {
    logout();
  }, [logout]);

  const [form] = Form.useForm();
  const [buttonLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [qr, setQR] = useState("");
  const [deepLink, setDeepLink] = useState([]);
  const qrRef = useRef(null);

  const [regNum, setRegNum] = useState("");
  const [emailNum, setEmailNum] = useState("");
  const [description, setDescription] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const handleInputChange = (e) => {
    e.persist();

    setRegNum(e.target.value);
    setQR("");
    setDeepLink("");
  };

  const handleEmailInputChange = (e) => {
    e.persist();

    setEmailNum(e.target.value);
    setQR("");
    setDeepLink("");
  };

  const scrollToQr = () => {
    console.log("clicking to QR");
    if (qrRef && qrRef.current) {
      qrRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleAfterOpenModal = () => {
    const firstTabStop = document.getElementById("modal-close-button");
    if (firstTabStop) {
      firstTabStop.focus();
    }
  };

  function handleLinkClick(link) {
    window.location.href = link;
  }

  const onCheck = async () => {
    if (regNum) {
      setLoading(true);

      await new Promise((resolve) =>
        setTimeout(() => {
          API.get("kinder-garden/check", {
            params: { regNum: regNum },
            headers: { Pragma: "no-cache" },
          })
            .then(
              (result) => {
                const resultData = result.data.content;
                if (resultData && result.data.success) {
                  setDescription(resultData);
                  setErrorDescription("");
                  showModal();
                } else {
                  const resultMessage = result.data.message;
                  if (resultMessage) {
                    message.error(resultMessage);
                    setErrorDescription(resultMessage);
                    // showModal();
                  } else {
                    message.error("QPAY-дээр алдаа гарлаа");
                    setErrorDescription("QPAY-дээр алдаа гарлаа");
                    // showModal();
                  }
                }

                setLoading(false);
              },
              (error) => {
                console.log(error);
                setLoading(false);
              }
            )
            .finally(() => {
              setLoading(false);
            });
        }, 3000)
      );
    }
  };

  const onFinish = (values) => {
    const { regNum, emailNum } = values;
    if (regNum && emailNum) {
      setLoading(true);
      setDescription("");
      setErrorDescription("");

      API.get("kinder-garden/charge-initiate", {
        params: { regNum: regNum, clientEmail: emailNum },
        headers: { Pragma: "no-cache" },
      })
        .then(
          (result) => {
            const resultData = result.data.content;
            if (resultData) {
              if (resultData.qPay_QRimage) {
                setQR(resultData.qPay_QRimage);
                setDeepLink(resultData.qPay_deeplink);

                scrollToQr();
              } else {
                message.error(resultData.name);
              }
            } else {
              message.error("QPAY-дээр алдаа гарлаа");
            }

            setLoading(false);
          },
          (error) => {
            console.log(error);

            setLoading(false);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  return (
    <div className="bg-primary bg-cover min-vh-100">
      <div className="account-pages pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5">
                <img
                  src={require("../../assets/images/logo-white.png")}
                  height="125"
                  alt="logoz"
                />
              </div>
            </div>
          </div>
          <Modal
            onAfterOpen={handleAfterOpenModal}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered={true}
            footer={[
              <Button
                id="modal-close-button"
                key="ok"
                type="primary"
                onClick={handleOk}
                tabIndex="1"
              >
                Close
              </Button>,
            ]}
          >
            <div style={{ textAlign: "center" }}>
              <Alert
                style={{ width: "100%", textAlign: "center" }}
                message={
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                }
                type="info"
              />
            </div>
          </Modal>

          <div className="row justify-content-center">
            <div className="col-xl-5 col-sm-8">
              <Spin
                spinning={buttonLoading}
                indicator={<DotLoader color="#23c37e" />}
              >
                <div className="card vat-content-border">
                  <div className="card-body p-4">
                    <div className="p-2">
                      <h3 className="text-center" style={{ color: "black" }}>
                        2025-2026 ОНЫ ХИЧЭЭЛИЙН ЖИЛИЙН НЭМЭЛТ ЭЛСЭЛТИЙН ТАНИЛЦАХ
                        ХИЧЭЭЛ, УУЛЗАЛТ ЯРИЛЦЛАГЫН БҮРТГЭЛ
                      </h3>
                      <Form
                        className="form-horizontal"
                        form={form}
                        name="login-form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-4">
                              <Form.Item
                                label="Хүүхдийн регистрийн дугаар"
                                name="regNum"
                                rules={[
                                  {
                                    required: true,
                                    pattern: new RegExp(
                                      /^[\p{а-яА-ЯёүөЁҮӨ}]{2}[0-9]{8}$/
                                    ),
                                    message: "Хүүхдийн регистрийн дугаараа оруулна уу?",
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  placeholder="УБ00000000"
                                  className="vat-input-border"
                                  onChange={handleInputChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group mb-4">
                              <Form.Item
                                label="И-баримт авах имэйл"
                                name="emailNum"
                                rules={[
                                  {
                                    required: true,
                                    message: "Имэйл оруулна уу?",
                                  },
                                ]}
                              >
                                <Input
                                  type="email"
                                  placeholder="oyunlag@gmail.com"
                                  className="vat-input-border"
                                  onChange={handleEmailInputChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group mb-4">
                              <Alert
                                message="Төлбөрийн баримт болон Танилцах хичээл-Уулзалт ярилцлагын цаг захиалах линк илгээгдэх тул мэйл хаягаа сайтар нягтална уу. Таны гүйлгээ амжилттай болоход төлбөрийн И-баримт үүсэх бөгөөд ШАЛГАХ товчийг дарснаар танд илгээгдэнэ. /Төлбөр буцаах боломжгүй/"
                                type="warning"
                              />
                            </div>
                            <div className="mt-4 d-flex justify-content-between">
                              <Button
                                type="primary"
                                size="large"
                                className={
                                  "btn btn-preschool btn-success btn-block btn-preschool-text"
                                }
                                htmlType="submit"
                                loading={buttonLoading}
                              >
                                Төлөх
                              </Button>
                            </div>
                            <div className="mt-4 d-flex justify-content-between">
                              <Button
                                type="primary"
                                size="large"
                                className={
                                  "btn btn-preschool btn-info btn-block btn-preschool-text"
                                }
                                onClick={onCheck}
                                loading={buttonLoading}
                              >
                                Шалгах
                              </Button>
                            </div>

                            <Divider />
                            {description || errorDescription ? (
                              <>
                                <div className="container-fluid mt-4 d-flex justify-content-center p-0">
                                  <Alert
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    message={
                                      errorDescription ? (
                                        <span>{errorDescription}</span>
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: description,
                                          }}
                                        />
                                      )
                                    }
                                    type={
                                      errorDescription ? "warning" : "success"
                                    }
                                  />
                                </div>
                                <Divider />
                              </>
                            ) : (
                              <div></div>
                            )}

                            {qr && (
                              <Button
                                type="primary"
                                size="large"
                                className={
                                  "d-flex justify-content-center align-items-center btn btn-preschool btn-success btn-block go-top active"
                                }
                                onClick={() => scrollToQr()}
                                loading={buttonLoading}
                              >
                                <i className="mdi mdi-cash-usd font-size-24"></i>
                              </Button>

                              // <div
                              //   class="go-top active"
                              //   onClick={() => scrollToQr()}
                              // >
                              //   <i className="mdi mdi-cash-usd font-size-24"></i>
                              // </div>
                            )}

                            {qr ? (
                              <div ref={qrRef} className="qr-section">
                                <div className="d-flex justify-content-center">
                                  <p>QPay уншуулж төлбөрөө төлнө үү.</p>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <img src={`data:image/png;base64,${qr}`} />
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {deepLink && deepLink.length > 0 ? (
                              <div className="carousel-wrapper">
                                <Divider />
                                <div className="text-center">
                                  <p>
                                    Гар утасны аппликэйшн ашиглах төлөх бол
                                    банкаа сонгоно уу.
                                  </p>
                                </div>
                                <div className="row">
                                  {deepLink.map((item, index) => (
                                    <div className="col-4 mb-3" key={index}>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                          borderRadius: "8px",
                                          boxShadow:
                                            "0 0 5px rgba(0, 0, 0, 0.2)",
                                        }}
                                        onClick={() =>
                                          handleLinkClick(item.link)
                                        }
                                      >
                                        <img
                                          src={item.logo}
                                          alt={item.name}
                                          className="img-fluid"
                                          style={{
                                            maxWidth: "100%",
                                            height: "auto",
                                            borderRadius: "8px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  return { loggedIn };
}

const actionCreators = {
  login: userActions.login,
  getUserData: userActions.getUserData,
  logout: userActions.logout,
};

const connectedLogin = connect(mapState, actionCreators)(PreSchool);

export { connectedLogin as PreSchool };
